import { useEffect } from 'react';
import { useNavigation, useLocation } from 'react-router-dom';

// usePageTracking関数を定義してエクスポート
export const usePageTracking = (): void => {
  const navigation = useNavigation();
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    if (navigation.state === 'idle') {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.href,
          path: location.pathname,
          title: document.title,
        },
      });
    }
  }, [navigation.state, location]);
};
